import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Image, message, Row, Skeleton, Tooltip, Typography } from 'antd';
import React from 'react';
import { ColProps } from 'antd/lib/grid/col';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import { fallbackImgBase64 } from '../../../../util/imageUtils';
import {
  GeneralSystemSettingsQueryQuery,
  InstanceSettingEnum, InstanceSettingFragmentFragment,
  UpdateInstanceSettingMutationMutation, UpdateInstanceSettingMutationMutationVariables
} from '../../../../../gql/typings';

const left: ColProps = { span: 4 };
const right: ColProps = { span: 20 };

// TODO: Make sure to add tooltip resources!
const typeNames: Record<'NAME'|'DOMAIN_PATH'|'IMG_PATH', {
  typeLabel: { id: string };
  tooltip?: { id: string };
}> = {
  [InstanceSettingEnum.NAME]: {
    typeLabel: Locale.Attribute.Name,
    // tooltip: { id: 'ab' },
  },
  [InstanceSettingEnum.DOMAIN_PATH]: {
    typeLabel: Locale.Attribute.Domain_path,
    // tooltip: { id: 'ab' },
  },
  [InstanceSettingEnum.IMG_PATH]: {
    typeLabel: Locale.Attribute.Login_footer_image,
    // tooltip: { id: 'ab' },
  },
};

const GeneralSystemSettings: React.FC = () => {
  const localization = useLocalization();
  const { data, loading } = useQuery<GeneralSystemSettingsQueryQuery>(DATA_QUERY);
  const [
    updateSetting,
  ] = useMutation<UpdateInstanceSettingMutationMutation, UpdateInstanceSettingMutationMutationVariables>(INSTANCE_MUTATION);
  const [messageApi, contextHolder] = message.useMessage();

  if (loading) return <Skeleton active />;

  const onUpdate = (enumType: InstanceSettingEnum) => (value: string) => {
    const type = Object
      .values(data!)
      .filter(e => typeof e === 'string' ? false : e?.enum == enumType)
      ?.[0] as InstanceSettingFragmentFragment|null;

    if (value === type?.value) return;
    updateSetting({
      variables: { type: enumType, value },
    }).then(() => {
      // @ts-ignore
      const messages = typeNames[type?.enum];

      const undo = () => updateSetting({
        variables: { type: enumType, value: type?.value },
      }).then(() => close());
      const close = messageApi.success(
        <span>
          {localization.formatMessage(Locale.Text.Successfully_updated_type_value, {
            type: <strong>{localization.formatMessage(messages.typeLabel)}</strong>,
            previousValue: <strong>{type?.value}</strong>,
            newValue: <strong>{value}</strong>,
          })}
          <Button type="link" onClick={undo}>
            {localization.formatMessage(Locale.Command.Undo)}
          </Button>
        </span>
      );
    });
  };

  return (
    <div className="general-system-settings-container">
      {contextHolder}
      {data && <Row>
        <Col {...left}>
          <Tooltip title={typeNames.NAME.tooltip ? localization.formatMessage(typeNames.NAME.tooltip) : ''}>
            <strong>
              {localization.formatMessage(typeNames.NAME.typeLabel)}
            </strong>:
          </Tooltip>
        </Col>
        <Col {...right}>
          <Typography.Paragraph editable={{ onChange: onUpdate(InstanceSettingEnum.NAME) }}>
            {data.name?.value}
          </Typography.Paragraph>
        </Col>

        <Col {...left}>
          <Tooltip title={typeNames.DOMAIN_PATH.tooltip ? localization.formatMessage(typeNames.DOMAIN_PATH.tooltip) : ''}>
            <strong>
              {localization.formatMessage(typeNames.DOMAIN_PATH.typeLabel)}
            </strong>:
          </Tooltip>
        </Col>
        <Col {...right}>
          <Typography.Paragraph editable={{ onChange: onUpdate(InstanceSettingEnum.DOMAIN_PATH) }}>
            {data.domain?.value}
          </Typography.Paragraph>
        </Col>

        <Col {...left}>
          <Tooltip title={typeNames.IMG_PATH.tooltip ? localization.formatMessage(typeNames.IMG_PATH.tooltip) : ''}>
            <strong>
              {localization.formatMessage(typeNames.IMG_PATH.typeLabel)}
            </strong>:
          </Tooltip>
        </Col>
        <Col {...right}>
          <figure>
            <Image
              style={{ maxHeight: 200, maxWidth: 400 }}
              src={data?.img?.value || ''}
              fallback={fallbackImgBase64}
              preview={false}
            />
            <figcaption>
              <Typography.Paragraph editable={{ onChange: onUpdate(InstanceSettingEnum.IMG_PATH) }}>
                {data.img?.value || <i>{localization.formatMessage(Locale.Text.No_image_provided)}</i>}
              </Typography.Paragraph>
            </figcaption>
          </figure>
        </Col>
      </Row>}
    </div>
  );
};

const INSTANCE_MUTATION = gql`
  mutation UpdateInstanceSettingMutation($type: InstanceSettingEnum!, $value: String) {
    updateInstanceSetting(type: $type, newValue: $value) {
      code
      value
    }
  }
`;

const DATA_QUERY = gql`
  query GeneralSystemSettingsQuery {
    name: instanceSetting(type: NAME) { ...InstanceSettingFragment }
    img: instanceSetting(type: IMG_PATH) { ...InstanceSettingFragment }
    domain: instanceSetting(type: DOMAIN_PATH) { ...InstanceSettingFragment }
  }
  fragment InstanceSettingFragment on InstanceSetting {
    code
    value
    enum
  }
`;

export default GeneralSystemSettings;
