import React from 'react';
import ConfigureActivityTypes from './ConfigureActivityTypes';
import AnchoredSections, { AnchoredItem } from '../../adminComponents/AdminSection/AnchoredSections';
import ConfigureFieldDays from './ConfigureFieldDays';
import { usePermission } from '../../../../permission/usePermission';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import ConfigureTableColumnSettings from '../../../../components/ConfigureTableColumnSettings/ConfigureTableColumnSettings';
import {
  TableColumnConfigureSettingType
} from '../../../../components/ConfigureTableColumnSettings/useTableColumnConfigureSettings';
import AdminConfigureSearch from '../../adminComponents/AdminConfigureSearch/AdminConfigureSearch';
import AdminExternalId from '../../adminComponents/AdminExternalId/AdminExternalId';
import { EntityTypeEnum } from '../../../../../gql/typings';

const ActivityAdminSettings: React.FC = () => {
  const localization = useLocalization();
  const permission = usePermission('ActivityType');
  return (
    <div className="activity-admin-settings-container">
      <AnchoredSections>
        <AnchoredItem anchor="types" title="Activity Types" hidden={!permission.read}>
          {permission.read && <ConfigureActivityTypes permission={permission} />}
        </AnchoredItem>

        <AnchoredItem anchor="search" title={localization.formatMessage(Locale.Command.Search)}>
          <AdminConfigureSearch entityType={EntityTypeEnum.ACTIVITY} />
        </AnchoredItem>

        <AnchoredItem anchor="field-days" title="Field Days">
          <ConfigureFieldDays />
        </AnchoredItem>

        <AnchoredItem anchor="external-id" title={localization.formatMessage(Locale.Attribute.External_IDs)}>
          <AdminExternalId entityType={EntityTypeEnum.ACTIVITY} />
        </AnchoredItem>

        <AnchoredItem anchor="table-columns" title={localization.formatMessage(Locale.General.Table_columns)}>
          <ConfigureTableColumnSettings
            entityType={EntityTypeEnum.ACTIVITY}
            type={TableColumnConfigureSettingType.SYSTEM}
          />
        </AnchoredItem>

      </AnchoredSections>
    </div>
  );
};

export default ActivityAdminSettings;
