import React, { useEffect, useMemo, useState } from 'react';
import { Breadcrumb, Divider, Table } from 'antd';
import { gql } from '@apollo/client/core';
import { useApolloClient, useQuery } from '@apollo/client';
import { isArray } from 'lodash';
import { useLocalization } from '../../../../util/useLocalization';
import EntityPieCharts, { EntityPieChartsProps } from './EntityPieCharts';
import ApbTableHeader from '../../../Table/ApbTableHeader';
import ApbPagination from '../../../Table/ApbPagination';
import ActionsToTakeDropdown from '../../../ActionsToTake/ActionsToTakeDropdown';
import { DashboardCardTitle } from '../../DashboardCard';
import { defaultPerPage } from '../../../Table/utils';
import { entitiesTableConfigs } from '../../../../browse/search_old/types';
import { useTableColumns } from '../../../entitiesSearch/results/useTableColumns';
import {
  EntityTableQuickActions,
  usePersonTableQuickActions,
} from '../../../../browse/person/hooks/usePersonTableQuickActions';
import { useSiteTableQuickActions } from '../../../../browse/site/hooks/useSiteTableQuickActions';
import {
  DashboardPieFullscreenQueryQuery,
  DashboardPieFullscreenQueryQueryVariables,
  EntityTypeEnum
} from '../../../../../gql/typings';

type SupportedTypes = EntityTypeEnum.PERSON|EntityTypeEnum.SITE|EntityTypeEnum.ACTIVITY;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// const dataQueries: Record<SupportedTypes, [DocumentNode, ((props: EntitySearchColumnParamType) => ColumnsType<any>)]> = {
//   PERSON: [PersonTable_DATA_QUERY, PersonTableColumns],
//   SITE: [SiteTable_DATA_QUERY, SiteTableColumns],
//   ACTIVITY: [ActivitiesTable_DataQuery, ActivitiesTableColumns],
// };

const test: Record<SupportedTypes, () => Partial<ReturnType<EntityTableQuickActions>>> = {
  PERSON: () => usePersonTableQuickActions(undefined, { dynamicListSupport: false }),
  SITE: () => useSiteTableQuickActions(undefined, { dynamicListSupport: false }),
  ACTIVITY: () => ({}),
};

const DashboardPieFullscreen: React.FC<EntityPieChartsProps & {
  entityType: EntityTypeEnum;
  breadcrumbs: DashboardCardTitle;
}> = (props) => {
  const localization = useLocalization();
  const apolloClient = useApolloClient();
  const { data } = useQuery<DashboardPieFullscreenQueryQuery, DashboardPieFullscreenQueryQueryVariables>(DATA_QUERY, {
    variables: { itemConfigurationId: props.configuration.id }
  });
  // TODO: Add the sorting also here!
  const [dataSource, setDataSource] = useState<{ totalCount: number; nodes: { id: number }[] }>({ totalCount: 0, nodes: [] });
  const [loading, setLoading] = useState(true);
  const { tableSelection, entitiesSearchProps, HiddenRender } = test[props.entityType as SupportedTypes]?.();
  const criteria = useMemo(
    () => props.pie.getCriteria(props.pie.listData.length - 1),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.pie.listData],
  );

  const {
    columns,
    query,
    onTableChange,
  } = useTableColumns((data?.dashboardEntityPieSettings[0]?.entityType ?? EntityTypeEnum.PERSON) as SupportedTypes);

  useEffect(() => {
    const type = data?.dashboardEntityPieSettings[0]?.entityType as SupportedTypes;
    if (!type) return;
    setLoading(true);
    // setColumns(columnConfig.)
    apolloClient.query({
      query: entitiesTableConfigs[type].buildConnectionQuery(query),
      variables: {
        criteria: {
          ...criteria,
          fetchSize: {
            limit: tableSelection?.tableProps?.perPageState[0] ?? defaultPerPage,
            offset: tableSelection?.tableProps
              ? ((tableSelection.tableProps.pageState[0] - 1) * tableSelection.tableProps.perPageState[0])
              : 0,
          },
        }
      },
    }).then(res => {
      if (res.data?.connection) {
        setDataSource(res.data?.connection);
        tableSelection?.tableProps?.onDataChange(res.data.connection);
        tableSelection?.tableProps?.onVisibleKeysChange(res?.data?.connection?.nodes?.map((k: { id: number }) => k.id) ?? []);
      }
    }).finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, criteria, tableSelection?.tableProps?.pageState[0], tableSelection?.tableProps?.perPageState[0]]);

  return (
    <div className="dashboard-entity-pie-fullscreen">
      <Breadcrumb>
        {isArray(props.breadcrumbs)
          ? props.breadcrumbs.map((b, index) => b
            ? <Breadcrumb.Item key={index} onClick={b.onClick}>{localization.formatMessage(b.label)}</Breadcrumb.Item>
            : <Breadcrumb.Separator key={index} />) : <Breadcrumb.Item>{props.breadcrumbs}</Breadcrumb.Item>}
      </Breadcrumb>
      <EntityPieCharts {...props} allowLastSelected />
      <Divider />
      <ApbTableHeader
        count={dataSource.totalCount}
        selectedLabel={tableSelection?.tableProps?.rowSelection.selectedLabel}
      >
        <ActionsToTakeDropdown actions={entitiesSearchProps?.actions} />
      </ApbTableHeader>
      <Table
        loading={loading}
        pagination={false}
        columns={columns}
        rowKey={e => e.id}
        dataSource={dataSource.nodes}
        onChange={onTableChange}
        {...tableSelection?.tableProps}
      />
      {tableSelection?.tableProps && <ApbPagination
        perPageState={tableSelection?.tableProps.perPageState}
        pageState={tableSelection?.tableProps.pageState}
        totalCount={dataSource.totalCount}
      />}
      {HiddenRender && <HiddenRender />}
    </div>
  );
};

const DATA_QUERY = gql`
  query DashboardPieFullscreenQuery($itemConfigurationId: Int!) {
    dashboardEntityPieSettings(dashboardItemConfigurationId: $itemConfigurationId) {
      id
      entityType
    }
  }
`;

export default DashboardPieFullscreen;
